import coin_logo_btc from './logo/btc.svg'
import coin_logo_eth from './logo/eth.svg'
import coin_logo_usdt from './logo/usdt.svg'
import coin_logo_eos from './logo/eos.svg'
import coin_logo_ltc from './logo/ltc.svg'
import coin_logo_xrp from './logo/xrp.svg'
import coin_logo_etc from './logo/etc.svg'
import coin_logo_ada from './logo/ada.svg'
export default {
    BTC: coin_logo_btc,
    ETH: coin_logo_eth,
    USDT: coin_logo_usdt,
    EOS: coin_logo_eos,
    LTC: coin_logo_ltc,
    XRP: coin_logo_xrp,
    ETC: coin_logo_etc,
    ADA: coin_logo_ada
}